<template>
    <div id="page-lap-kunjungan-pasien">
      <b-container fluid>
        <b-row>
          <b-col cols="12" sm="12" md="12" lg="12" xl="12">
            <b-card>
              <b-row>
                <b-col cols="12" sm="12" md="12" lg="4" xl="4">
                  <b-form-group label-cols-md="4">
                    <template v-slot:label>
                      Dari
                    </template>
                    <b-form-datepicker
                      style="width: 100%"
                      :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                      no-flip
                      locale="id"
                      v-model="data_search.tanggal_awal"
                    ></b-form-datepicker>
                  </b-form-group>

                  <b-form-group label-cols-md="4">
                    <template v-slot:label>
                      Sampai
                    </template>
                    <b-form-datepicker
                      style="width: 100%"
                      :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                      no-flip
                      locale="id"
                      v-model="data_search.tanggal_akhir"
                    ></b-form-datepicker>
                  </b-form-group>
                </b-col>
                <b-col cols="12" sm="12" md="12" lg="4" xl="4">
                  <b-form-group label-cols-md="4">
                    <template v-slot:label>
                      Jenis Tenaga Medis
                    </template>
                    <multiselect
                      :options="options_jenis_tenaga_medis"
                      :multiple="false"
                      :searchable="true"
                      :close-on-select="true"
                      :show-labels="false"
                      placeholder="-- Pilih --"
                      label="nama_jenis_tenaga_medis"
                      size="sm"
                      v-model="data_search.jenis_tenaga_medis"
                      @input="getTenagaMedis(data_search.jenis_tenaga_medis)"
                    ></multiselect>
                  </b-form-group>
                  <b-form-group label-cols-md="4">
                    <template v-slot:label>
                      Tenaga Medis
                    </template>
                    <multiselect
                      :options="options_tenaga_medis"
                      :multiple="false"
                      :searchable="true"
                      :close-on-select="true"
                      :show-labels="false"
                      placeholder="-- Pilih --"
                      label="nama_tenaga_medis"
                      size="sm"
                      v-model="data_search.tenaga_medis"
                    ></multiselect>
                  </b-form-group>
                  
                </b-col>
                
              </b-row>

              <b-row>
                <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                  <div style="display:flex;justify-content:flex-start">
                    <b-button @click="getDataKunjungan()" variant="primary" class="mr-2">Tampilkan</b-button>
                    <!-- <b-button variant="success" class="mr-2">Export to Excel</b-button> -->
                    <b-button @click="reset()" variant="warning">Reset</b-button>
                  </div>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                  <hr>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                  <h5 style="text-align:center"><strong>LAPORAN HARIAN - PEMERIKSAAN MEDIS</strong></h5>
                  <h5 style="text-align:center; margin-top:5px"><strong>Total : {{ total }}</strong></h5>
                </b-col>
              </b-row>

              <b-row class="mt-4">
                <b-col md="9" offset-md="3">
                  <b-form-group
                    label="Cari"
                    label-for="filter-input"
                    label-cols-md="3"
                    label-align-md="right"
                    label-size="md"
                  >
                    <b-input-group size="md">
                  
                    <b-form-input
                        id="filter-input"
                        v-model="filter"
                        type="search"
                        placeholder="Ketik disini untuk mencari ..."
                      ></b-form-input>
                    

                      <b-input-group-append>
                        <b-button
                          :disabled="!filter"
                          @click="filter = ''"
                          variant="danger"
                          >Hapus</b-button
                        >
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="12" md="12" lg="12">
                  <b-table
                    :items="items"
                    :fields="fields"
                    :current-page="currentPage"
                    :per-page="perPage"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    responsive
                    show-empty
                    small
                    @filtered="onFiltered"
                    bordered
                    striped
                    hover
                    :busy="tableBusy"
                  >
                  </b-table>
                </b-col>
              </b-row>

              <b-row>
                <b-col md="3">
                  <b-form-group
                    label="Per Halaman"
                    label-for="per-page-select"
                    label-cols-md="6"
                    label-align-md="left"
                    label-size="md"
                    style="background-color: "
                  >
                    <b-form-select
                      id="per-page-select"
                      v-model="perPage"
                      :options="pageOptions"
                      size="md"
                    ></b-form-select>
                  </b-form-group>
                </b-col>
                <b-col md="6" offset-md="3">
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="totalRows"
                    :per-page="perPage"
                    align="fill"
                    size="sm"
                  ></b-pagination>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </template>
  
  <script>
  import Multiselect from "vue-multiselect";
  export default {
    name: "kunjungan_pasien",
    components: {
      Multiselect
    },
    data() {
      return {
        showing: false,
        variant: "success",
        msg: "",
        value: null,
        data_search: {
          tanggal_awal: null,
          tanggal_akhir: null,
          nama_pemeriksa: null,
          tenaga_medis: null,
          jenis_tenaga_medis: null,
          ms_jenis_tenaga_medis_id: null,
          halaman: 0,
          jumlah: 1000,
        },
        tanggalnya: null,
        total: null,
        jenis_kelamin: null,
        options_tenaga_medis: [],
        options_jenis_tenaga_medis: [],
        fields: [
        {
          key: "no",
          label: "No",
          sortDirection: "desc",
          sortable: true,
          class: "table-number text-center",
        },

        {
          key: "nama_pemeriksa",
          label: "Nama Pemeriksa",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "kelompok_pegawai",
          label: "Kelompok Pegawai",
          sortable: true,
          sortDirection: "desc",
          class: "custom-w-nama text-left",
        },
        {
          key: "jumlah_pemeriksaan",
          label: "Jumlah Pemeriksaan",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        
      ],
      items: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      // filterOn: [],
      tableBusy: false,
      };
    },
    computed: {
      sortOptions() {
        // Create an options list from our fields
        return this.fields
          .filter((f) => f.sortable)
          .map((f) => {
            return { text: f.label, value: f.key };
          });
      },
      filterOn() {
        // console.log(this.fields.map(el => el.key))
        return this.fields.map(el => el.key)
      },
    },
    activated(){
      this.data_search.tanggal_awal = this.$moment(new Date()).format("YYYY-MM-DD")
      this.data_search.tanggal_akhir = this.$moment(new Date()).format("YYYY-MM-DD")
      this.getDataKunjungan();
      this.getData()
    },
    // async mounted() {
    //   // Set the initial number of items
    //   this.totalRows = this.items.length;
    //   this.getDatas();
    // },
    methods: {
      onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length;
        this.currentPage = 1;
      },
      reset(){
        let vm = this
        vm.data_search.nama_pemeriksa=  null,
        vm.data_search.tenaga_medis=  null,
        vm.data_search.jenis_tenaga_medis=  null,
        vm.data_search.ms_jenis_tenaga_medis_id=  null,
        this.getTenagaMedis()
        this.getDataKunjungan()
      },
      async getTenagaMedis(x){
        let vm = this
        console.log(x);
        if(x){
            vm.data_search.tenaga_medis = null
            let tenaga_medis = await vm.$axios.post("/tenaga_medis/list", {ms_jenis_tenaga_medis_id: x.ms_jenis_tenaga_medis_id})
            // console.log(tenaga_medis, 'tenaga medis');
            if(tenaga_medis.data.data.length){
                vm.options_tenaga_medis = tenaga_medis.data.data
                // console.log(vm.options_tenaga_medis);
            }
        }else {
            let tenaga_medis = await vm.$axios.post("/tenaga_medis/list")
            // console.log(tenaga_medis, 'tenaga medis');
            if(tenaga_medis.data.data.length){
                vm.options_tenaga_medis = tenaga_medis.data.data
                // console.log(vm.options_tenaga_medis);
            }
        }
        
      },
      async getData(){
        let vm = this
        let tenaga_medis = await vm.$axios.post("/tenaga_medis/list")
        // console.log(tenaga_medis, 'tenaga medis');
        if(tenaga_medis.data.data.length){
            vm.options_tenaga_medis = tenaga_medis.data.data
            // console.log(vm.options_tenaga_medis);
        }
        let jenis_tenaga_medis = await vm.$axios.post("/ms_jenis_tenaga_medis/list")
        if(jenis_tenaga_medis.data.data.length){
            vm.options_jenis_tenaga_medis = jenis_tenaga_medis.data.data
            // console.log(vm.options_jenis_tenaga_medis);
        }
      },
      getDataKunjungan(){
        let vm = this
        if(vm.data_search.jenis_tenaga_medis){
          vm.data_search.ms_jenis_tenaga_medis_id = vm.data_search.jenis_tenaga_medis.ms_jenis_tenaga_medis_id
        }
        if(vm.data_search.tenaga_medis){
          vm.data_search.nama_pemeriksa = vm.data_search.tenaga_medis.nama_tenaga_medis
        }
        vm.$axios.post("/anamnesa/laporan_pemeriksaan_medis", vm.data_search)
        .then((res) => {
        //   console.log(res, 'ini respon');
          vm.items = res.data.data
          vm.total = 0
          for (let i = 0; i < vm.items.length; i++) {
            let x = vm.items[i];
            x.no = i+1
            vm.total += parseInt(x.jumlah_pemeriksaan)
          }
          vm.totalRows = vm.items.length
        })
        .catch((err) => {
          console.log(err);
        })
      },
      
      triggerAlert(event) {
        let vm = this;
        vm.$store.commit("set_alert", event);
      },
    },
  };
  </script>
  <style>
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  #page-lap-kunjungan-pasien .custom-table td{
    padding:0 !important;
    font-size:14px;
    font-weight:bold;
    border:hidden !important;
  }
  </style>
  